const { TableCell, Box } = require("@mui/material");
const { default: styled } = require("styled-components");

exports.TableCellHeader = styled(TableCell)(({ theme }) => ({
  color: "var(--bg-white)",
  fontWeight: "bold",
}));

exports.TableCellValue = styled(TableCell)(({ theme }) => ({
  verticalAlign: "top",
}));

exports.StatusBoxActive = styled(Box)({
  backgroundColor: "Var(--bg-green)",
  color: "Var(--text-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
});

exports.StatusBoxHistory = styled(Box)({
  backgroundColor: "Var(--bg-black)",
  color: "Var(--text-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
});

exports.StatusBoxPending = styled(Box)({
  backgroundColor: "Var(--red)",
  color: "Var(--text-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
});
