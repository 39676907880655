import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CommonButton from "../commenComponents/CommonButton";
import { useForm } from "react-hook-form";

export default function AddProductInfo({
  setShowInfoPage,
  action,
  setproduct,
  product,
}) {
  const { handleSubmit } = useForm();
  const setBacktoCatPage = () => {
    setShowInfoPage(false);
  };

  return (
    <div>
      <Box>
        <form id="addOneForm" onSubmit={handleSubmit(action)}>
          <Box sx={{ my: "20px" }}>
            <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
              Title
            </Typography>
            <Input
              value={product.title}
              placeholder={"Enter Title"}
              onChange={(e) =>
                setproduct({
                  ...product,
                  title: e.target.value,
                })
              }
              sx={{
                borderRadius: "5px",
                p: "5px",
                pl: "15px",
                width: "100%",
                color: "var(--font-black)",
                fontSize: "1.1rem",
                marginRight: "5px",
                background: "var(--bg-white)",
              }}
              disableUnderline
              required
            />
          </Box>
          <Box sx={{ my: "20px" }}>
            <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
              Brand (Skip for Restaurant and Grocery)
            </Typography>
            <Input
              placeholder={"Enter Brand"}
              value={product.brand}
              onChange={(e) =>
                setproduct({
                  ...product,
                  brand: e.target.value,
                })
              }
              sx={{
                borderRadius: "5px",
                p: "5px",
                pl: "15px",
                width: "100%",
                color: "var(--font-black)",
                fontSize: "1.1rem",
                marginRight: "5px",
                background: "var(--bg-white)",
              }}
              disableUnderline
            />
          </Box>
          {product.department === "640879ae3cee7036416aaa07" && (
            <Box>
              <FormControl component="fieldset">
                <RadioGroup
                  value={product.vegOrNonVeg}
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="top"
                    control={
                      <Radio
                        color="primary"
                        value={"Veg"}
                        onChange={(e) => {
                          setproduct({
                            ...product,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Veg"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="start"
                    control={
                      <Radio
                        color="primary"
                        value={"NonVeg"}
                        onChange={(e) => {
                          setproduct({
                            ...product,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Non Veg"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="start"
                    control={
                      <Radio
                        color="primary"
                        value={"Egg"}
                        onChange={(e) => {
                          setproduct({
                            ...product,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Egg"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="start"
                    control={
                      <Radio
                        color="primary"
                        value={"Other"}
                        onChange={(e) => {
                          setproduct({
                            ...product,
                            vegOrNonVeg: e.target.value,
                          });
                        }}
                      />
                    }
                    label="Other"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox />}
                      label="Delivery"
                      labelPlacement="start"
                      checked={product.delivery}
                      onChange={(e) =>
                        setproduct({
                          ...product,
                          delivery: e.target.checked,
                        })
                      }
                    />
                    <FormControlLabel
                      value="end"
                      control={<Checkbox />}
                      label="Pickup"
                      labelPlacement="start"
                      checked={product.pickup}
                      onChange={(e) =>
                        setproduct({
                          ...product,
                          pickup: e.target.checked,
                        })
                      }
                    />
                    <FormControlLabel
                      value="end"
                      control={<Checkbox />}
                      label="Dive in"
                      labelPlacement="start"
                      checked={product.diveIn}
                      onChange={(e) =>
                        setproduct({
                          ...product,
                          diveIn: e.target.checked,
                        })
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
          )}
          <div style={{ display: "flex", justifyContent: "end" }}>
            <CommonButton
              sx={{
                height: "40px",
                width: "90px",
                mr: "10px",
                textTransform: "none",
              }}
              variant="outlined"
              action={setBacktoCatPage}
            >
              Back
            </CommonButton>

            <CommonButton
              type="submit"
              sx={{
                height: "40px",
                width: "90px",
                background: "var(--red)",
                textTransform: "none",
              }}
              variant="contained"
              // action={action}
            >
              Next
            </CommonButton>
          </div>
        </form>
      </Box>
    </div>
  );
}
