import React from "react";
import Button from "@mui/material/Button";

const CommonButton = ({
  children,
  color,
  disabled,
  size,
  sx,
  variant,
  action,
  type,
}) => {
  return (
    <Button
    
      type
      color={color}
      disabled={disabled}
      size={size}
      sx={sx}
      variant={variant}
      onClick={action}
    >
      {children}
    </Button>
  );
};

export default CommonButton;
