import React from 'react'
import Moment from 'react-moment'

function DateFormatter({date}) {
  return (
    <Moment format='L'>
        {date}
    </Moment>
  )
}

export default DateFormatter
