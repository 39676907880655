import React from "react";
import CommonButton from "../commenComponents/CommonButton";
import SearchBar from "../commenComponents/SearchBar";

export default function InventoryFilterBar() {
  return (
    <div
      style={{
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SearchBar searchBarWidth={"200px"} placeholder={"Search"} />
      <CommonButton
        sx={{
          height: "40px",
          background: "var(--red)",
          textTransform: "none",
        }}
        variant="contained"
      >
        Add Product
      </CommonButton>
    </div>
  );
}
