import { useState } from "react";
import { Box } from "@mui/material";
import CommonButton from "../commenComponents/CommonButton";
import AddAdvancedpage from "./AddAdvancedpage";
import AddProductInfo from "./AddProductInfo";
import AddVariation from "./AddVariation";

export default function AddProductInfoTopbar({
  setShowInfoPage,
  product,
  setproduct,
  allProperty,
  setAllProperty,
  saveProduct,
}) {
  const [proInfo, setProInfo] = useState("Info");

  const Variation = () => {
    setProInfo("Variation");
  };
  const Advanced = () => {
    setProInfo("Advanced");
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: { lg: "60%", md: "80%", sm: "100%" },
        }}
      >
        <CommonButton
          sx={{
            height: "40px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: "bold",
            color:
              proInfo === "Info" ||
              proInfo === "Variation" ||
              proInfo === "Advanced"
                ? "var(--red)"
                : "",
          }}
          variant="text"
        >
          Product Info 🠺
        </CommonButton>

        <CommonButton
          sx={{
            height: "40px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: "bold",
            color:
              proInfo === "Advanced" || proInfo === "Variation"
                ? "var(--red)"
                : "",
          }}
          variant="text"
        >
          Variation 🠺
        </CommonButton>
        <CommonButton
          sx={{
            height: "40px",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: "bold",
            color: proInfo === "Advanced" ? "var(--red)" : "",
          }}
          variant="text"
        >
          Advanced 🠺
        </CommonButton>
      </Box>
      <Box>
        {proInfo === "Info" ? (
          <AddProductInfo
            setShowInfoPage={setShowInfoPage}
            action={Variation}
            product={product}
            setproduct={setproduct}
          />
        ) : proInfo === "Variation" ? (
          <AddVariation
            action={Advanced}
            product={product}
            setproduct={setproduct}
            allProperty={allProperty}
            setAllProperty={setAllProperty}
            setProInfo={setProInfo}
          />
        ) : (
          <AddAdvancedpage
            product={product}
            setproduct={setproduct}
            saveProduct={saveProduct}
            setProInfo={setProInfo}
          />
        )}
      </Box>
    </div>
  );
}
