import { combineReducers } from "redux";
import { vendorReducer } from "./reducers/VendorReducer";
import { masterReducer } from "./reducers/MasterReducer";

const rootReducer = combineReducers({
  master: masterReducer,
  vendor: vendorReducer,
});

export default rootReducer;
