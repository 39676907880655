import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

function OrderHistory() {
  return (
    <Card
      elevation={0}
      sx={{ width: 600, height: "180px", margin: "15px", padding: 3 }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 16 }}
          color="var(--font-black)"
          gutterBottom
        >
          Order History
        </Typography>
        <Typography variant="body2" color="var(--font-black)">
          View detailed order history made by your business
        </Typography>
      </CardContent>
      <CardActions>
        &nbsp; <Button size="small">View Report</Button>
      </CardActions>
    </Card>
  );
}

export default OrderHistory;
