import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Input,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonButton from "../commenComponents/CommonButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function AddAdvancedpage({
  product,
  setproduct,
  saveProduct,
  setProInfo,
}) {
  const [bulletPoint, setBulletPoint] = useState("");

  const setBacktoVariation = () => {
    setProInfo("Variation");
  };

  const addPoint = () => {
    if (bulletPoint !== "" && !product.bullets.includes(bulletPoint)) {
      setproduct({
        ...product,
        bullets: [bulletPoint, ...product.bullets],
      });
      setBulletPoint("");
    }
  };

  const deletePoint = (indexNo) => {
    let bulles = product.bullets.filter((item, index) => {
      if (index !== indexNo) {
        return item;
      }
    });

    setproduct({
      ...product,
      bullets: bulles,
    });
  };

  const onTagsChange = (event, values) => {
    console.log(values);
    setproduct({
      ...product,
      keyWords: values,
    });
  };
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "47%" }}>
          <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
            Sale Start Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={product.saleStartDate}
              onChange={(newValue) => {
                setproduct({
                  ...product,
                  saleStartDate: newValue.$d,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: "5px",
                    width: "100%",

                    color: "var(--font-black)",
                    fontSize: "1.1rem",
                    marginRight: "5px",
                    background: "var(--bg-white)",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}
              disableUnderline
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ width: "47%" }}>
          <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
            Sale End Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={product.saleEndDate}
              onChange={(newValue) => {
                setproduct({
                  ...product,
                  saleEndDate: newValue.$d,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: "5px",
                    width: "100%",

                    color: "var(--font-black)",
                    fontSize: "1.1rem",
                    marginRight: "5px",
                    background: "var(--bg-white)",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}
              disableUnderline
            />
          </LocalizationProvider>
        </Box>
        {/* ///////////////////// */}
      </Box>
      <Box sx={{ my: "20px" }}>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Maximum Order Quantity
        </Typography>
        <Input
          value={product.orderQty}
          type={"number"}
          onChange={(e) => {
            setproduct({
              ...product,
              orderQty: e.target.value,
            });
          }}
          sx={{
            borderRadius: "5px",
            p: "5px",
            pl: "15px",
            width: "100%",
            color: "var(--font-black)",
            fontSize: "1.1rem",
            marginRight: "5px",
            background: "var(--bg-white)",
          }}
          disableUnderline
        />
      </Box>
      <Box sx={{ my: "20px" }}>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Shipping Time
        </Typography>
        <Input
          type="number"
          value={product.shippingTime}
          onChange={(e) => {
            setproduct({
              ...product,
              shippingTime: e.target.value,
            });
          }}
          sx={{
            borderRadius: "5px",
            p: "5px",
            pl: "15px",
            width: "100%",
            color: "var(--font-black)",
            fontSize: "1.1rem",
            marginRight: "5px",
            background: "var(--bg-white)",
          }}
          disableUnderline
        />
      </Box>

      <Box sx={{ my: "20px" }}>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Description
        </Typography>
        <Input
          value={product.description}
          multiline
          rows={4}
          onChange={(e) => {
            setproduct({
              ...product,
              description: e.target.value,
            });
          }}
          sx={{
            borderRadius: "5px",
            p: "5px",
            pl: "15px",
            width: "100%",
            color: "var(--font-black)",
            fontSize: "1.1rem",
            marginRight: "5px",
            background: "var(--bg-white)",
          }}
          disableUnderline
        />
      </Box>

      <Box sx={{ my: "20px" }}>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Bullet Points
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Input
            value={bulletPoint}
            placeholder="Bullet Points"
            onChange={(e) => {
              setBulletPoint(e.target.value);
            }}
            sx={{
              borderRadius: "5px",
              p: "5px",
              pl: "15px",
              width: "100%",
              color: "var(--font-black)",
              fontSize: "1.1rem",
              background: "var(--bg-white)",
            }}
            disableUnderline
          />
          <CommonButton
            sx={{
              height: "43px",
              width: "120px",
              background: "var(--red)",
              textTransform: "none",
            }}
            variant="contained"
            action={addPoint}
          >
            Add Points
          </CommonButton>
        </Box>
        <Box sx={{ borderTop: "solid var(--border-gray) 1px" }}>
          {product.bullets.map((row, index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "5px",
                p: "5px",
                pl: "15px",
                width: "100%",
                color: "var(--font-black)",
                fontSize: "1.1rem",
                marginRight: "5px",
                background: "var(--bg-white)",
              }}
            >
              <ListItem
                sx={{
                  display: "list-item",
                  justifyContent: "space-between",
                }}
              >
                {row}
              </ListItem>

              <Button
                variant="text"
                sx={{
                  height: "100%",
                  fontSize: "16px",
                  textTransform: "none",
                  color: "var(--font-black)",
                }}
                onClick={() => deletePoint(index)}
              >
                <DeleteOutlineIcon />
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ my: "10px" }}>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Keyword
        </Typography>
        <Autocomplete
          multiple
          value={product.keyWords}
          options={[].map((option) => "")}
          freeSolo
          onChange={onTagsChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Keyword"
              sx={{
                borderRadius: "5px",
                width: "100%",
                color: "var(--font-black)",
                fontSize: "1.1rem",
                background: "var(--bg-white)",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          )}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", my: "20px" }}>
        <CommonButton
          sx={{
            height: "40px",
            width: "90px",
            mr: "10px",
            textTransform: "none",
          }}
          variant="outlined"
          action={setBacktoVariation}
        >
          Back
        </CommonButton>

        <CommonButton
          sx={{
            height: "40px",
            width: "90px",
            background: "var(--red)",
            textTransform: "none",
          }}
          variant="contained"
          action={saveProduct}
        >
          Submit
        </CommonButton>
      </Box>
    </div>
  );
}
