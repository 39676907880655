import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function TopCard({ content, value }) {
  return (
    <Card elevation={0} sx={{ width: 390, margin: "15px" }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="var(--font-black)"
          gutterBottom
        >
          {content}
        </Typography>
        <Typography variant="h5" component="div" color={"var(--red)"}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TopCard;
