import { Box } from "@mui/material";
import InventoryFilterBar from "../../components/vendor/Inventory/InventoryFilterBar";
import InventoryTable from "../../components/vendor/Inventory/InventoryTable";
import ThirdNavbar from "../../components/vendor/vendorNavbar/ThirdNavbar";

function Inventory() {
  const navBarItems = [
    {
      name: "Manage Inventory",
      status: true,
      show: true,
    },
  ];
  return (
    <Box
      sx={{
        paddingTop: "173px",
      }}
    >
      <ThirdNavbar items={navBarItems} />
      <div style={{ paddingRight: "50px", paddingLeft: "50px" }}>
        <InventoryFilterBar />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        ></div>

        <InventoryTable />
      </div>
    </Box>
  );
}

export default Inventory;
