import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  addProductImages,
  deleteProductImages,
  deleteVariation,
  fetchAllVendorProduct,
  updateProduct,
} from "../../../services/vendor/vendor";
import { useState, useEffect } from "react";
import { Button, styled, Switch, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateFormatter from "../../../utils/DateFormatter";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

function Row(props) {
  const {
    row,
    index,
    editFieldOpen,
    setEditFieldOpen,
    editdata,
    setEditdata,
    inventoryUpdate,
    setOldImage,
    editVariationData,
    setEditVariationData,
    sentMessage,
  } = props;
  const vendor = useSelector((state) => state.vendor);
  const [open, setOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);

  const showEditField = (id) => {
    setEditFieldOpen(id);
  };
  const cancelEditForm = async () => {
    if (uploadedImage.length >= 1) {
      await deleteProductImages(uploadedImage, vendor.token);
    }
    setEditFieldOpen("");
    setOldImage([]);
  };
  const updateVariationValue = (value, field, variantIndex) => {
    const newTable = editVariationData.map((item, index) => {
      if (variantIndex === index) {
        if (field == "productName") {
          let letter = value.slice(0, 4).toUpperCase();
          let digit = Date.now().toString().slice(8, 13);
          return { ...item, [field]: value, sku: letter + digit };
        } else {
          return { ...item, [field]: value };
        }
      }
      return item;
    });
    setEditVariationData(newTable);
  };

  const uploadVarientImage = async (e, indexNo) => {
    const oldImages = editdata.varients.filter((item, index) => {
      if (index === indexNo) {
        return item;
      }
    });
    setOldImage(oldImages[0].image);
    const formData = new FormData();
    for (let i = 0; i < e.length; i++) {
      formData.append("images", e[i]);
    }

    const data = await addProductImages(formData, vendor.token);
    if (data.status) {
      const newTable = editVariationData.map((item, index) => {
        if (index === indexNo) {
          return { ...item, image: data.images };
        }
        return item;
      });
      setUploadedImage(data.images);
      setEditVariationData(newTable);
    }
  };
  const setEditVariationFunction = (variation) => {
    const data = variation.map((item) => {
      return item.varientId;
    });
    setEditVariationData(data);
  };
  const deleteVariationAction = async (proId, qty, varId) => {
    console.log(proId, qty, varId);
    const data = await deleteVariation(proId, qty, varId, vendor.token);
    sentMessage(data);
  };
  return (
    <>
      {editFieldOpen === row._id ? (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell align="left">{index + 1}</TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {editdata?.varients.length}
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <img
                src={editVariationData[0]?.image[0]}
                alt=""
                srcset=""
                style={{ height: "60px", width: "60px" }}
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                value={editdata?.title}
                size="small"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) =>
                  setEditdata({
                    ...editdata,
                    title: e.target.value,
                  })
                }
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                value={editdata.orderQty}
                sx={{ width: "100px" }}
                size="small"
                id="outlined-basic"
                variant="outlined"
                type={"number"}
                onChange={(e) =>
                  setEditdata({
                    ...editdata,
                    orderQty: e.target.value,
                  })
                }
              />
            </TableCell>

            <TableCell align="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={editdata.saleStartDate}
                  onChange={(newValue) => {
                    setEditdata({
                      ...editdata,
                      saleStartDate: newValue.$d,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        borderRadius: "5px",

                        width: "150px",
                        color: "var(--font-black)",
                        fontSize: "1.1rem",
                        marginRight: "5px",
                        background: "var(--bg-white)",
                      }}
                    />
                  )}
                  disableUnderline
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell align="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={editdata.saleEndDate}
                  onChange={(newValue) => {
                    setEditdata({
                      ...editdata,
                      saleEndDate: newValue.$d,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        borderRadius: "5px",

                        width: "150px",
                        color: "var(--font-black)",
                        fontSize: "1.1rem",
                        marginRight: "5px",
                        background: "var(--bg-white)",
                      }}
                    />
                  )}
                  disableUnderline
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell align="center">
              <Box>
                <Button
                  sx={{
                    width: "20px",
                    color: "var(--button-gray)",
                  }}
                  onClick={() => cancelEditForm()}
                >
                  <HighlightOffIcon />
                </Button>
                <Button
                  sx={{
                    width: "20px",
                    color: "var(--button-green)",
                  }}
                  onClick={() => inventoryUpdate()}
                >
                  <CheckCircleIcon />
                </Button>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                background: "var(--bg-primary)",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Variations
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">No</StyledTableCell>
                        <StyledTableCell align="center">
                          Selected Image
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Choose Image
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Product Name
                        </StyledTableCell>
                        <StyledTableCell align="center">SKU</StyledTableCell>
                        <StyledTableCell align="center">Stock</StyledTableCell>
                        <StyledTableCell align="center">
                          Unit Price
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Sale Price
                        </StyledTableCell>
                        {editdata.property1 && (
                          <StyledTableCell align="center">
                            {editdata.property1}
                          </StyledTableCell>
                        )}
                        {editdata.property2 && (
                          <StyledTableCell align="center">
                            {editdata.property2}
                          </StyledTableCell>
                        )}
                        {editdata.property3 && (
                          <StyledTableCell align="center">
                            {editdata.property3}
                          </StyledTableCell>
                        )}
                        {editdata.property4 && (
                          <StyledTableCell align="center">
                            {editdata.property4}
                          </StyledTableCell>
                        )}
                        {editdata.property5 && (
                          <StyledTableCell align="center">
                            {editdata.property5}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="right">
                          Seller Status
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editVariationData.map((varientsRow, index) => (
                        <TableRow key={varientsRow._id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            <img
                              src={varientsRow.image[0]}
                              alt=""
                              srcset=""
                              style={{ width: "60px", height: "60px" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              type="file"
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              sx={{ width: "129px" }}
                              onChange={(e) =>
                                uploadVarientImage(e.target.files, index)
                              }
                              InputProps={{
                                inputProps: {
                                  accept: "image/*",
                                  multiple: true,

                                  max: 4,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={varientsRow.productName}
                              sx={{ width: "130px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "productName",
                                  index
                                )
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow.sku}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={varientsRow.stock}
                              type={"number"}
                              sx={{ width: "100px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "stock",
                                  index
                                )
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={varientsRow.salePrice}
                              type={"number"}
                              sx={{ width: "130px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "salePrice",
                                  index
                                )
                              }
                            />
                          </TableCell>

                          <TableCell align="center">
                            <TextField
                              value={varientsRow.unitPrice}
                              type={"number"}
                              sx={{ width: "130px" }}
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) =>
                                updateVariationValue(
                                  e.target.value,
                                  "unitPrice",
                                  index
                                )
                              }
                            />
                          </TableCell>
                          {editdata.property1 && (
                            <TableCell align="center">
                              {varientsRow.value1}
                            </TableCell>
                          )}
                          {editdata.property2 && (
                            <TableCell align="center">
                              {varientsRow.value2}
                            </TableCell>
                          )}
                          {editdata.property3 && (
                            <TableCell align="center">
                              {varientsRow.value3}
                            </TableCell>
                          )}
                          {editdata.property4 && (
                            <TableCell align="center">
                              {varientsRow.value4}
                            </TableCell>
                          )}
                          {editdata.property5 && (
                            <TableCell align="center">
                              {varientsRow.value5}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <Switch
                              sx={{ marginTop: "0px" }}
                              checked={varientsRow.sellerStatus}
                              onChange={() =>
                                // handleSwitchChange(row._id, !row.isActive)

                                updateVariationValue(
                                  !varientsRow.sellerStatus,
                                  "sellerStatus",
                                  index
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              color="warning"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell align="left">{index + 1}</TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {row?.varients?.length}
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <img
                src={row.varients[0]?.varientId?.image[0]}
                alt=""
                srcset=""
                style={{ height: "60px", width: "60px" }}
              />
            </TableCell>
            <TableCell align="center">{row?.title}</TableCell>
            <TableCell align="center">{row?.orderQty}</TableCell>
            <TableCell align="center">
              <DateFormatter date={row?.saleStartDate} />
            </TableCell>
            <TableCell align="center">
              <DateFormatter date={row?.saleEndDate} />
            </TableCell>
            <TableCell align="center">
              <Box>
                <Button
                  sx={{
                    width: "20px",
                  }}
                  onClick={() => {
                    showEditField(row._id);
                    setEditdata(row);
                    setEditVariationFunction(row.varients);
                  }}
                >
                  <DescriptionIcon />
                </Button>
                <Button
                  sx={{
                    width: "20px",
                    color: "var(--red)",
                  }}
                  disabled
                >
                  <DeleteIcon />
                </Button>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                background: "var(--bg-primary)",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Variations
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">No</StyledTableCell>
                        <StyledTableCell align="center">Image</StyledTableCell>
                        <StyledTableCell align="center">
                          Product Name
                        </StyledTableCell>
                        <StyledTableCell align="center">SKU</StyledTableCell>
                        <StyledTableCell align="center">Stock</StyledTableCell>
                        <StyledTableCell align="center">
                          Unit Price
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Sale Price
                        </StyledTableCell>
                        {row.property1 && (
                          <StyledTableCell align="center">
                            {row.property1}
                          </StyledTableCell>
                        )}
                        {row.property2 && (
                          <StyledTableCell align="center">
                            {row.property2}
                          </StyledTableCell>
                        )}
                        {row.property3 && (
                          <StyledTableCell align="center">
                            {row.property3}
                          </StyledTableCell>
                        )}
                        {row.property4 && (
                          <StyledTableCell align="center">
                            {row.property4}
                          </StyledTableCell>
                        )}
                        {row.property5 && (
                          <StyledTableCell align="center">
                            {row.property5}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="center">
                          Seller Status
                        </StyledTableCell>
                        <StyledTableCell align="right">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.varients.map((varientsRow, index) => (
                        <TableRow key={varientsRow?.varientId?._id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            <img
                              src={varientsRow?.varientId?.image[0]}
                              alt=""
                              srcset=""
                              style={{ width: "60px", height: "60px" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.productName}
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.sku}
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.stock}
                          </TableCell>
                          <TableCell align="center">
                            {varientsRow?.varientId?.salePrice}
                          </TableCell>

                          <TableCell align="center">
                            {varientsRow?.varientId?.unitPrice}
                          </TableCell>
                          {row.property1 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value1}
                            </TableCell>
                          )}
                          {row.property2 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value2}
                            </TableCell>
                          )}
                          {row.property3 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value3}
                            </TableCell>
                          )}
                          {row.property4 && (
                            <TableCell align="center">
                              {varientsRow?.varientId?.value4}
                            </TableCell>
                          )}
                          {row.property5 && (
                            <TableCell align="center">
                              {varientsRow.varientId?.value5}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <Switch
                              disabled
                              sx={{ marginTop: "0px" }}
                              checked={varientsRow?.varientId?.sellerStatus}
                              inputProps={{ "aria-label": "controlled" }}
                              color="warning"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              sx={{
                                width: "20px",
                                color: "var(--red)",
                              }}
                              onClick={() =>
                                deleteVariationAction(
                                  row._id,
                                  row.varients.length,
                                  varientsRow?.varientId?._id
                                )
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

export default function InventoryTable() {
  const vendor = useSelector((state) => state.vendor);
  const [allProduct, setAllProduct] = useState([]);
  const [editFieldOpen, setEditFieldOpen] = useState("");
  const [editdata, setEditdata] = useState();
  const [editVariationData, setEditVariationData] = useState();
  const [oldImage, setOldImage] = useState([]);

  useEffect(() => {
    fetchAllproduct();
  }, [editFieldOpen]);
  const fetchAllproduct = async () => {
    const data = await fetchAllVendorProduct(vendor.token);
    setAllProduct(data);
  };

  const inventoryUpdate = async () => {
    const data = await updateProduct(editdata, editVariationData, vendor.token);
    console.log(oldImage);

    if (data.status) {
      if (oldImage && oldImage.length >= 1) {
        await deleteProductImages(oldImage, vendor.token);
      }
      setEditFieldOpen("");
    }
  };
  const sentMessage = (data) => {
    if (data.status) {
      fetchAllproduct();
      toast.success(data.message);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <StyledTableCell align="left">No</StyledTableCell>
            <StyledTableCell align="center">Variation</StyledTableCell>
            <StyledTableCell align="center">Image</StyledTableCell>
            <StyledTableCell align="center">Title</StyledTableCell>
            <StyledTableCell align="center">Max Order Qty</StyledTableCell>
            <StyledTableCell align="center">Sale Start Date</StyledTableCell>
            <StyledTableCell align="center">Sale End Date</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allProduct.map((row, index) => (
            <Row
              key={row.name}
              row={row}
              index={index}
              editFieldOpen={editFieldOpen}
              setEditFieldOpen={setEditFieldOpen}
              editdata={editdata}
              setEditdata={setEditdata}
              inventoryUpdate={inventoryUpdate}
              setOldImage={setOldImage}
              editVariationData={editVariationData}
              setEditVariationData={setEditVariationData}
              sentMessage={sentMessage}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
