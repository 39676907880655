import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CatalogueFilterBar from "../../components/vendor/catalogue/CatalogueFilterBar";
import SingleProduct from "../../components/vendor/catalogue/SingleProductCard";
import ThirdNavbar from "../../components/vendor/vendorNavbar/ThirdNavbar";
import { fetchAllVendorProduct } from "../../services/vendor/vendor";

export default function Catalogue() {
  const vendor = useSelector((state) => state.vendor);

  const navBarItems = [
    {
      name: "Items",
      status: true,
      show: true,
    },
  ];
  const [allProduct, setAllProduct] = useState([]);

  useEffect(() => {
    fetchAllproduct();
  }, []);

  const fetchAllproduct = async () => {
    const data = await fetchAllVendorProduct(vendor.token);
    setAllProduct(data);
  };

  return (
    <Box
      sx={{
        paddingTop: "173px",
      }}
    >
      <ThirdNavbar items={navBarItems} />
      <div style={{ paddingRight: "50px", paddingLeft: "50px" }}>
        <CatalogueFilterBar />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {allProduct.map((item) => (
            <SingleProduct item={item} />
          ))}
        </div>
      </div>
    </Box>
  );
}
