import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

export default function ThirdNavbar({ items }) {
  return (
    <Box sx={{}}>
      <AppBar
        sx={{
          background: "var(--bg-white)",
          marginTop: "130px",
          borderBottom: "solid var(--border-gray) 1px",
        }}
        elevation={0}
      >
        <div style={{ display: "flex", marginLeft: "70px" }}>
          {items?.map((row, index) => (
            <>
              {row.show && (
                <Button
                  key={index}
                  sx={{
                    color: row.status ? "var(--red)" : "var(--font-black)",
                    fontSize: 15,
                    fontWeight: 500,
                    marginY: "5px",
                    marginRight: "10px",
                    textTransform: "none",
                  }}
                  onClick={() => row.action(index)}
                >
                  {row.name}
                </Button>
              )}
            </>
          ))}
        </div>
      </AppBar>
    </Box>
  );
}
