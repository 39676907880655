import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";

const SearchBar = ({ placeholder, onChange, searchBarWidth }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "solid var(--border-gray) 1px",
        background: "var(--bg-white)",
        height: "40px",
        padding: "10px",
        borderRadius: "5px",
        justifyContent: "space-between",
      }}
    >
      <Input
        placeholder={placeholder}
        onChange={onChange}
        sx={{
          width: searchBarWidth,
          color: "var(--font-black)",
          fontSize: "1.1rem",
          marginRight: "5px",
        }}
        disableUnderline
      />
      <SearchIcon />
    </Box>
  );
};

export default SearchBar;
