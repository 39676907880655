import Cookies from "js-cookie";

export function masterReducer(
  state = Cookies.get("master") ? JSON.parse(Cookies.get("master")) : null,
  action
) {
  switch (action.type) {
    case "MASTER_LOGIN":
      return action.payload;

    case "MASTER_LOGOUT":
      return null;

    default:
      return state;
  }
}
