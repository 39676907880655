import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Switch } from "@mui/material";

export default function SingleProductCard({ item }) {
  return (
    <div>
      <Card sx={{ width: 280, m: "10px" }}>
        <div
          style={{
            display: "flex",
            paddingTop: "10px",
            paddingBottom: "20px",
            background: "var(--bg-card)",
          }}
        >
          <Switch
            sx={{ marginTop: "0px" }}
            // checked={row.isActive}
            // onChange={() => handleSwitchChange(row._id, !row.isActive)}
            inputProps={{ "aria-label": "controlled" }}
            color="warning"
          />
          <CardMedia
            component="img"
            height="160"
            sx={{ width: 144, marginTop: "10px", marginX: "10px" }}
            image={item.varients[0].varientId.image[0]}
            alt="Chevrolet"
          />
        </div>
        <CardContent>
          <Typography
            sx={{ fontWeight: 400, my: "3px" }}
            style={{ fontSize: "13px" }}
          >
            SKU : {item.varients[0].varientId.sku}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold", my: "3px" }}
            style={{ fontSize: "14px" }}
          >
            {item.varients[0].varientId.productName}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} style={{ fontSize: "13px" }}>
            No. of Stock : {item.varients[0].varientId.stock}
          </Typography>
          <div style={{ marginTop: "15px", display: "flex" }}>
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ fontSize: "13px", marginRight: "20px" }}
            >
              MRP : $ {item.varients[0].varientId.unitPrice}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ fontSize: "13px", color: "var(--red)" }}
            >
              SALE : $ {item.varients[0].varientId.salePrice}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
