import { Divider } from "@mui/material";
import React from "react";
import VendorMainNavbar from "./VendorMainNavbar";
import VendorSubNavbar from "./VendorSubNavbar";

export default function VendorNavbar() {
  return (
    <div>
      <div>
        <VendorMainNavbar />
      </div>
      <Divider />
      <div>
        <VendorSubNavbar />
      </div>
    </div>
  );
}
