import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Input, Typography } from "@mui/material";

import CommonButton from "../commenComponents/CommonButton";
import { TableCellHeader, TableCellValue, StatusBoxActive } from "./order";

export default function ActiveOrder() {
  const [time, setTime] = useState(1);
  return (
    <TableContainer component={Paper} sx={{ mt: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "var(--bg-green)" }}>
          <TableRow style={{ color: "white" }}>
            <TableCellHeader align="center">Date</TableCellHeader>
            <TableCellHeader align="center">Items</TableCellHeader>
            <TableCellHeader align="center">Customer</TableCellHeader>
            <TableCellHeader align="center">Status</TableCellHeader>
            <TableCellHeader align="center">Action</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>
            </TableCellValue>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>
            </TableCellValue>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>
            </TableCellValue>
            <TableCellValue sx={{ display: "flex", justifyContent: "center" }}>
              {/* <Box sx={{width:'100px'}}>dsa</Box> */}
              <StatusBoxActive>Active</StatusBoxActive>
            </TableCellValue>
            <TableCellValue align="center">
              <Typography>John son</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Otp (Get from Delivery Boy)
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    border: "solid var(--border-black) 1px",
                    p: "8px",
                    width: "180px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Input
                    sx={{
                      width: "100px",
                      height: "20px",
                      "& input": {
                        textAlign: "center",
                      },
                    }}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    disableUnderline
                  />
                </Box>
              </Box>
              <Box>
                <CommonButton
                  variant="contained"
                  // color="primary"
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "50px",
                    width: "100px",
                    mr: "10px",
                  }}
                >
                  Returned
                </CommonButton>
                <CommonButton
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: "10px",
                    mt: "10px",
                    textTransform: "none",
                    height: "50px",
                    width: "100px",
                    backgroundColor: "var(--button-gray)",
                    color: "var(--font-black)",
                    weight: "500",
                  }}
                >
                  Cancel
                </CommonButton>
              </Box>
              <Box>
                <CommonButton
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "15px",
                    width: "100px",
                    mr: "10px",
                    color: "var(--font-black)",
                  }}
                >
                  View Order
                </CommonButton>
                <CommonButton
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "15px",
                    width: "100px",
                    mr: "10px",
                    color: "var(--font-black)",
                  }}
                >
                  Delivered
                </CommonButton>
              </Box>
            </TableCellValue>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
