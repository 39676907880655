import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Input, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CommonButton from "../commenComponents/CommonButton";
import { TableCellHeader, TableCellValue, StatusBoxPending } from "./order";

export default function PendingOrder() {
  const [time, setTime] = useState(1);
  function incrementValue() {
    setTime(Number(time) + 1);
  }
  function decrementValue() {
    setTime(Number(time) - 1);
  }
  return (
    <TableContainer component={Paper} sx={{ mt: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "var(--red)" }}>
          <TableRow>
            <TableCellHeader align="center">Date</TableCellHeader>
            <TableCellHeader align="center">Items</TableCellHeader>
            <TableCellHeader align="center">Customer</TableCellHeader>
            <TableCellHeader align="center">Status</TableCellHeader>
            <TableCellHeader align="center">Action</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                Order id #111111
              </Typography>
            </TableCellValue>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>
            </TableCellValue>
            <TableCellValue align="center">
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>{" "}
              <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
                1 X iphone 13 pro green
              </Typography>
            </TableCellValue>
            <TableCellValue sx={{ display: "flex", justifyContent: "center" }}>
              {/* <Box sx={{width:'100px'}}>dsa</Box> */}
              <StatusBoxPending>Pending</StatusBoxPending>
            </TableCellValue>
            <TableCellValue align="center">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    border: "solid var(--border-black) 1px",
                    p: "8px",
                    width: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      decrementValue();
                    }}
                  >
                    <RemoveIcon />
                  </button>

                  <Input
                    sx={{
                      width: "100px",
                      "& input": {
                        textAlign: "center",
                      },
                    }}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    disableUnderline
                  />
                  <button onClick={incrementValue}>
                    <AddIcon />
                  </button>
                </Box>
              </Box>
              <Box>
                <CommonButton
                  variant="contained"
                  // color="primary"
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "50px",
                    width: "100px",
                    mr: "10px",
                  }}
                >
                  Accept
                </CommonButton>
                <CommonButton
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: "10px",
                    width: "110px",
                    textTransform: "none",
                    fontSize: "11px",
                    height: "50px",
                    backgroundColor: "var(--button-gray)",
                    color: "var(--font-black)",
                    weight: "500",
                  }}
                >
                  Mark as out of stock
                </CommonButton>
              </Box>
              <Box>
                <CommonButton
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "15px",
                    width: "100px",
                    mr: "10px",
                    color: "var(--font-black)",
                  }}
                >
                  Accept
                </CommonButton>
                <CommonButton
                  sx={{
                    mt: "10px",
                    textTransform: "none",
                    height: "15px",
                    width: "100px",
                    mr: "10px",
                    color: "var(--font-black)",
                  }}
                >
                  Delivered
                </CommonButton>
              </Box>
            </TableCellValue>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
