import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const vendorRegisterSchema = Yup.object({
  title: Yup.string().required("title is required"),
  first_name: Yup.string().required("first_name is required"),
  last_name: Yup.string().required("last_name is required"),
  phone: Yup.number()
    .typeError("field must be a number")
    .required("phone is required"),
  mobile: Yup.number()
    .typeError("field must be a number")
    .required("mobile is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("password is required"),
  businessName: Yup.string().required("businessName is required"),
  businessAddress: Yup.string().required("businessAddress is required"),
  addressLine1: Yup.string().required("addressLine1 is required"),
  addressLine2: Yup.string().required("addressLine2 is required"),
  city: Yup.string().required("city is required"),
  postalCode: Yup.number()
    .typeError("field must be a number")
    .required("postalCode is required"),
  businessStartTime: Yup.string().required("required"),
  businessEndTime: Yup.string().required(" required"),
  minOrderValue: Yup.number()
    .typeError("field must be a number")
    .required("minOrderValue is required"),
  deliveryChargePerItem: Yup.number()
    .typeError("field must be a number")
    .required("deliveryChargePerItem is required"),
  deliveryRadius: Yup.number()
    .typeError("field must be a number")
    .required("deliveryRadius is required"),
  freeDeliveryValue: Yup.number()
    .typeError("field must be a number")
    .required("freeDeliveryValue is required"),
});
