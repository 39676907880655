import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProduct } from "../../../services/vendor/vendor";
import AddProductInfo from "./AddProductInfoTopbar";
import ProductCategory from "./ProductCategory";

export default function AddProduct() {
  const vendor = useSelector((state) => state.vendor);
  const [showInfoPage, setShowInfoPage] = useState(false);
  const navigate = useNavigate();

  const [product, setproduct] = useState({
    title: "",
    brand: "",
    saleStartDate: new Date(),
    saleEndDate: new Date(),
    orderQty: 0,
    description: "",
    vegOrNonVeg: "Veg",
    property1: "",
    property2: "",
    property3: "",
    property4: "",
    property5: "",
    delivery: true,
    pickup: false,
    diveIn: false,
    bullets: [],
    keyWords: [],
    image: [],
    varients: [
      {
        image: "",
        productName: "",
        stock: 1,
        sku: "",
        unitPrice: 0,
        salePrice: 0,
        sellerStatus: true,
        isDeleted: false,
      },
    ],
    addOns: [
      // {
      //   addonName: "",
      //   minQty: 0,
      //   maxQty: 0,
      //   value: [""],
      //   price: [0],
      // },
    ],
    department: "",
    category: "",
  });
  const [allProperty, setAllProperty] = useState([]);

  const saveProduct = async () => {
    const data = await addProduct(product, vendor.token);
    if (data.status) {
      navigate("/catalogue");
    }
  };

  return (
    <div>
      <Box
        sx={{
          maxWidth: { lg: "80%", md: "80%", sm: "90%" },
          height: "100%",
          mx: "auto",
          paddingTop: "20px",
        }}
      >
        <div>
          {showInfoPage ? (
            <div>
              <AddProductInfo
                setShowInfoPage={setShowInfoPage}
                product={product}
                setproduct={setproduct}
                allProperty={allProperty}
                setAllProperty={setAllProperty}
                saveProduct={saveProduct}
              />
            </div>
          ) : (
            <div>
              <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                Product Category
              </Typography>
              <ProductCategory
                setShowInfoPage={setShowInfoPage}
                product={product}
                setproduct={setproduct}
              />
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}
