import { Box } from "@mui/material";

function Dashboard() {
  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - 270px)` },
          ml: { sm: `270px` },
        }}
      >
        Dashboard
      </Box>
    </>
  );
}

export default Dashboard;
