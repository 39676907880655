import React from "react";
import { Link } from "react-router-dom";
import CommonButton from "../commenComponents/CommonButton";
import SearchBar from "../commenComponents/SearchBar";

export default function CatalogueFilterBar() {
  return (
    <div
      style={{
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SearchBar searchBarWidth={"200px"} placeholder={"Search"} />
      <Link to={"/addproduct"}>
        <CommonButton
          sx={{
            height: "40px",
            background: "var(--red)",
            textTransform: "none",
          }}
          variant="contained"
        >
          Add Product
        </CommonButton>
      </Link>
    </div>
  );
}
