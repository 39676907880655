import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CommonButton from "../commenComponents/CommonButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  addProductImages,
  deleteProductImages,
} from "../../../services/vendor/vendor";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

export default function AddVariation({
  action,
  product,
  setproduct,
  allProperty,
  setAllProperty,
  setProInfo,
}) {
  const vendor = useSelector((state) => state.vendor);
  const [property, setProperty] = useState("");
  const [provalue, setValue] = useState("");
  const [singleAddOnsData, setSingleAddOnsnsData] = useState({
    addonName: "",
    minQty: 0,
    maxQty: 0,
    value: [""],
    price: [0],
    image: "",
  });

  const [render, setRender] = useState(false);

  const setBacktoinfo = () => {
    setProInfo("Info");
  };

  const generateProduct = () => {
    let property1Val = "";
    let property2Val = "";
    let property3Val = "";
    let property4Val = "";
    let property5Val = "";
    function findCombinations(arrays, currentCombination = []) {
      if (!arrays.length) {
        return [currentCombination];
      }
      const [currentArray, ...remainingArrays] = arrays;
      const combinations = [];
      for (const element of currentArray) {
        const newCombination = [...currentCombination, element];
        const subCombinations = findCombinations(
          remainingArrays,
          newCombination
        );
        combinations.push(...subCombinations);
      }
      return combinations;
    }
    const newArray = allProperty.map((nestedArray) => nestedArray.slice(1));
    const newArray2 = newArray.filter((nestedArray) => {
      if (nestedArray.length > 0) {
        return nestedArray.slice(1);
      }
    });
    const combinations = findCombinations(newArray2);

    let arrr = [];
    combinations.forEach((item, index) => {
      let objValue = {};
      allProperty.forEach((item2, index2) => {
        if (item2.length > 1) {
          let data;
          if (index2 === 0) {
            data = { value1: combinations[index][index2] };
          } else if (index2 === 1) {
            data = { value2: combinations[index][index2] };
          } else if (index2 === 2) {
            data = { value3: combinations[index][index2] };
          } else if (index2 === 3) {
            data = { value4: combinations[index][index2] };
          } else {
            data = { value5: combinations[index][index2] };
          }
          let data2 = objValue;
          objValue = Object.assign({}, data2, data);
        }
      });
      arrr.push({
        image: "",
        productName: "",
        sku: "",
        stock: 1,
        unitPrice: 0,
        salePrice: 0,
        ...objValue,
        sellerStatus: true,
        isDeleted: false,
      });
    });

    allProperty.map((item, index) => {
      if (index === 0 && item.length > 1) {
        property1Val = item[0].trim();
      } else if (index === 1 && item.length > 1) {
        property2Val = item[0].trim();
      } else if (index === 2 && item.length > 1) {
        property3Val = item[0].trim();
      } else if (index === 3 && item.length > 1) {
        property4Val = item[0].trim();
      } else if (index === 4 && item.length > 1) {
        property5Val = item[0].trim();
      }
    });

    setproduct({
      ...product,
      varients: arrr,
      property1: property1Val,
      property2: property2Val,
      property3: property3Val,
      property4: property4Val,
      property5: property5Val,
    });
  };
  console.log(product);
  const addProperty = () => {
    setRender(true);

    if (property !== "" && !allProperty.includes(property)) {
      setAllProperty([...allProperty, [property]]);
      setProperty("");
    }
  };
  const addValue = (value) => {
    setRender(true);

    if (provalue) {
      let data = allProperty.map((item, index) => {
        if (index === value) {
          let val = [...item, provalue];
          document.getElementById(value).reset();
          setValue("");
          return val;
        } else {
          return item;
        }
      });
      setAllProperty(data);
    }
  };

  const deleteProperty = async (value) => {
    setRender(true);
    let data = allProperty.filter((item, index) => {
      if (index !== value) {
        return item;
      }
    });
    await setAllProperty(data);
  };

  const deleteValue = async (value, proindex) => {
    setRender(true);
    let data2 = allProperty.map((item, index) => {
      if (proindex !== index) {
        return item;
      } else {
        let data = item?.filter((item2, index2) => {
          if (index2 !== value) {
            return item2;
          }
        });
        return data;
      }
    });
    await setAllProperty(data2);
  };

  const deleteTableRow = async (indexNo) => {
    const oldImages = product.varients.filter((item, index) => {
      if (index === indexNo) {
        return item;
      }
    });
    const image = oldImages[0].image;
    let newTable = product.varients.filter((item, index) => {
      if (indexNo !== index) {
        return item;
      }
    });
    setproduct({
      ...product,
      varients: newTable,
    });

    if (product.varients.length <= 1) {
      setproduct({
        ...product,
        varients: [
          {
            image: "",
            productName: "",
            stock: 1,
            unitPrice: 0,
            salePrice: 0,
            sku: "",
            sellerStatus: true,
            isDeleted: false,
          },
        ],
      });
    }
    await deleteProductImages(image);
  };

  const changeProName = (value, field, indexNo) => {
    const newTable = product.varients.map((item, index) => {
      if (index === indexNo) {
        if (field == "productName") {
          let letter = value.slice(0, 4).toUpperCase();
          let digit = Date.now().toString().slice(8, 13);
          return { ...item, [field]: value, sku: letter + digit };
        } else {
          return { ...item, [field]: value };
        }
      }
      return item;
    });
    setproduct({
      ...product,
      varients: newTable,
    });
  };

  const deleteAddons = async (deleteIndex) => {
    const newAddOns = product.addOns.filter((item, index) => {
      if (index !== deleteIndex) {
        return item;
      }
    });

    setproduct({
      ...product,
      addOns: newAddOns,
    });
    if (product.addOns.length) {
      setproduct({
        ...product,
        addOns: newAddOns,
      });
    }
  };

  useEffect(() => {
    if (render) {
      generateProduct();
    }
  }, [allProperty]);

  const changeSigleAddOnPrice = (value, adoneIndex, indexNo) => {
    let data = product.addOns.map((item, index) => {
      if (adoneIndex === index) {
        let retData = item.price.map((item2, index2) => {
          if (indexNo === index2) {
            return value;
          } else {
            return item2;
          }
        });
        return { ...item, price: retData };
      } else {
        return item;
      }
    });
    setproduct({
      ...product,
      addOns: data,
    });
  };
  const changeSigleAddOnValue = (value, field, adoneIndex, indexNo) => {
    console.log(adoneIndex, field);
    let data = product.addOns.map((item, index) => {
      if (adoneIndex === index) {
        let retData = item.value.map((item2, index2) => {
          if (indexNo === index2) {
            return { ...item2, [field]: value };
          } else {
            return item2;
          }
        });
        return { ...item, value: retData };
      } else {
        return item;
      }
    });

    setproduct({
      ...product,
      addOns: data,
    });
  };

  const changeNameMinMax = (value, field, indexNo) => {
    let data = product.addOns.map((item, index) => {
      if (indexNo === index) {
        return { ...item, [field]: value };
      } else {
        return item;
      }
    });
    setproduct({
      ...product,
      addOns: data,
    });
  };
  const addNewOption = (addonindex) => {
    const addOns = product.addOns.map((items, index) => {
      if (addonindex === index) {
        let returndata = {
          ...items,
          value: [
            ...items.value,
            {
              name: "",
              price: 0,
            },
          ],
        };
        return returndata;
      } else {
        return items;
      }
    });

    setproduct({
      ...product,
      addOns: addOns,
    });
  };

  const AddNewAdon = () => {
    console.log(product);
    setproduct({
      ...product,
      addOns: [
        {
          addonName: "",
          minQty: 0,
          maxQty: 0,
          value: [
            {
              name: "",
              price: 0,
            },
          ],
        },
        ...product.addOns,
      ],
    });
  };
  const uploadVarientImage = async (e, indexNo) => {
    const oldImages = product.varients.filter((item, index) => {
      if (index === indexNo) {
        return item;
      }
    });
    const image = oldImages[0].image;

    const formData = new FormData();
    for (let i = 0; i < e.length; i++) {
      formData.append("images", e[i]);
    }

    const data = await addProductImages(formData, vendor.token);
    if (data.status) {
      const newTable = product.varients.map((item, index) => {
        if (index === indexNo) {
          return { ...item, image: data.images };
        }
        return item;
      });
      setproduct({
        ...product,
        varients: newTable,
      });
      if (image.length >= 1) {
        await deleteProductImages(image, vendor.token);
      }
    }
  };
  console.log(product.addOns);
  return (
    <div>
      <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
        Add Variation
      </Typography>
      <Box>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "13px" }}>
          Property (eg: Size, Color )
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Input
            value={property}
            placeholder={"Property (eg: Size, Color )"}
            onChange={(e) => {
              setProperty(e.target.value);
            }}
            sx={{
              borderRadius: "5px",
              p: "5px",
              pl: "15px",
              width: "100%",
              color: "var(--font-black)",
              fontSize: "1.1rem",
              marginRight: "5px",
              background: "var(--bg-white)",
            }}
            disableUnderline
          />
          <CommonButton
            sx={{
              height: "100%",
              fontSize: "16px",
              textTransform: "none",
              color: "var(--font-black)",
              background: "var(--button-gray)",
            }}
            action={addProperty}
            variant="text"
          >
            Add
          </CommonButton>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {allProperty?.map((item, index) => (
            <Box
              sx={{
                border: "solid var(--border-gray) 1px",
                width: "300px",
                minHeight: "300px",
                px: "20px",
                m: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: "10px",
                }}
              >
                <Typography
                  // sx={{ my: "10px" }}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Property:
                  <span style={{ color: "red" }}>{item[0]}</span>
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    height: "30px",
                    fontSize: "16px",
                    textTransform: "none",
                    color: "var(--bg-white)",
                    background: "var(--red)",
                  }}
                  onClick={() => {
                    deleteProperty(index);
                  }}
                >
                  Delete
                </Button>
              </Box>

              <Box sx={{ mt: "20px", display: "flex" }}>
                <form
                  id={index}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Input
                    placeholder={"Value one"}
                    // value={provalue}
                    onChange={(e) => setValue(e.target.value)}
                    sx={{
                      borderRadius: "5px",
                      p: "5px",
                      pl: "15px",
                      width: "100%",
                      color: "var(--font-black)",
                      fontSize: "1.1rem",
                      marginRight: "5px",
                      background: "var(--bg-white)",
                    }}
                    disableUnderline
                  />
                </form>
                <Button
                  variant="text"
                  sx={{
                    height: "100%",
                    fontSize: "16px",
                    textTransform: "none",
                    color: "var(--font-black)",
                    background: "var(--button-gray)",
                  }}
                  onClick={() => addValue(index)}
                >
                  Add
                </Button>
              </Box>
              <Box>
                <List sx={{ listStyleType: "disc", pl: 4 }}>
                  {item?.map((row, ind) => (
                    <>
                      {ind !== 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <ListItem
                            sx={{
                              display: "list-item",
                              justifyContent: "space-between",
                            }}
                          >
                            {row}
                          </ListItem>

                          <Button
                            variant="text"
                            sx={{
                              height: "100%",
                              fontSize: "16px",
                              textTransform: "none",
                              color: "var(--font-black)",
                            }}
                            onClick={() => deleteValue(ind, index)}
                          >
                            <DeleteOutlineIcon />
                          </Button>
                        </Box>
                      )}
                    </>
                  ))}
                </List>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box>
        <TableContainer component={Paper} sx={{ my: "10px" }}>
          <Table
            sx={{ minWidth: 650, backgroundColor: "#FFFFFF" }}
            aria-label="simple table"
          >
            <TableHead style={{ backgroundColor: "#D9D9D9" }}>
              <TableRow sx={{ fontWeight: "bold" }}>
                <StyledTableCell align="left" sx={{ width: "20px" }}>
                  No
                </StyledTableCell>
                <StyledTableCell align="center">First Image</StyledTableCell>
                <StyledTableCell align="center">Image</StyledTableCell>
                <StyledTableCell align="center">Product Name</StyledTableCell>
                {product.property1 && (
                  <StyledTableCell align="center">
                    {product.property1}
                  </StyledTableCell>
                )}
                {product.property2 && (
                  <StyledTableCell align="center">
                    {product.property2}
                  </StyledTableCell>
                )}
                {product.property3 && (
                  <StyledTableCell align="center">
                    {product.property3}
                  </StyledTableCell>
                )}
                {product.property4 && (
                  <StyledTableCell align="center">
                    {product.property4}
                  </StyledTableCell>
                )}
                {product.property5 && (
                  <StyledTableCell align="center">
                    {product.property5}
                  </StyledTableCell>
                )}
                {/* <StyledTableCell align="center">Sku</StyledTableCell> */}
                <StyledTableCell align="center">Stock</StyledTableCell>
                <StyledTableCell align="center">Unit Price</StyledTableCell>
                <StyledTableCell align="center">Sale Price</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.varients?.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "10px",
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <img
                      style={{ width: "80px", height: "60px" }}
                      src={row.image[0]}
                      alt=""
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="file"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onChange={(e) =>
                        uploadVarientImage(e.target.files, index)
                      }
                      InputProps={{
                        inputProps: {
                          accept: "image/*",
                          multiple: true,

                          max: 4,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      value={row.productName}
                      placeholder={"Please Enter Product Name"}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(e) =>
                        changeProName(e.target.value, "productName", index)
                      }
                    />
                  </TableCell>
                  {product.property1 && (
                    <TableCell align="center">{row?.value1}</TableCell>
                  )}
                  {product.property2 && (
                    <TableCell align="center">{row?.value2}</TableCell>
                  )}
                  {product.property3 && (
                    <TableCell align="center">{row?.value3}</TableCell>
                  )}
                  {product.property4 && (
                    <TableCell align="center">{row?.value4}</TableCell>
                  )}
                  {product.property5 && (
                    <TableCell align="center">{row?.value5}</TableCell>
                  )}
                  {/* <TableCell align="center">{row.sku}</TableCell> */}
                  <TableCell align="center">
                    <TextField
                      value={row.stock}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      sx={{ width: "80px" }}
                      onChange={(e) =>
                        changeProName(e.target.value, "stock", index)
                      }
                      type={"Number"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      value={row.unitPrice}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      sx={{ width: "100px" }}
                      onChange={(e) =>
                        changeProName(e.target.value, "unitPrice", index)
                      }
                      type={"Number"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <TextField
                      value={row.salePrice}
                      size="small"
                      id="outlined-basic"
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onChange={(e) =>
                        changeProName(e.target.value, "salePrice", index)
                      }
                      type={"Number"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="text"
                      sx={{
                        height: "100%",
                        fontSize: "16px",
                        textTransform: "none",
                        color: "var(--red)",
                      }}
                      onClick={() => deleteTableRow(index)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ my: "10px" }}
            style={{ fontSize: "13px", fontWeight: "bold" }}
          >
            Add Add ons
          </Typography>

          <CommonButton
            type="submit"
            sx={{
              height: "40px",
              width: "90px",
              background: "var(--red)",
              textTransform: "none",
            }}
            variant="contained"
            action={AddNewAdon}
          >
            Add
          </CommonButton>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {product.addOns.map((row, index) => (
            <Box
              sx={{
                p: "10px",
                border: "solid var(--border-gray) 1px",
                my: "20px",
                width: "300px",
                mx: "10px",
                flexWrap: "wrap",
              }}
            >
              <form id="addOneForm">
                <Box sx={{ display: "flex" }}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    size="small"
                    sx={{ background: "white", height: "40px", width: "100%" }}
                    value={row.addonName}
                    placeholder={"Add on (Choose or Add)"}
                    onChange={(e) =>
                      changeNameMinMax(e.target.value, "addonName", index)
                    }
                    required
                  />
                  <Button
                    variant="text"
                    sx={{
                      height: "100%",
                      fontSize: "16px",
                      textTransform: "none",
                      color: "var(--font-black)",
                    }}
                    onClick={() => deleteAddons(index)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Box>

                <Box sx={{ display: "flex", my: "10px" }}>
                  <TextField
                    type={"number"}
                    label="Min"
                    variant="outlined"
                    size="small"
                    sx={{ background: "white", height: "40px", mr: "10px" }}
                    value={row.minQty}
                    onChange={(e) =>
                      changeNameMinMax(e.target.value, "minQty", index)
                    }
                  />

                  <TextField
                    type={"number"}
                    label="Max"
                    variant="outlined"
                    size="small"
                    sx={{ background: "white", height: "40px" }}
                    value={row.maxQty}
                    onChange={(e) =>
                      changeNameMinMax(e.target.value, "maxQty", index)
                    }
                  />
                </Box>
                {row?.value?.map((item2, index2) => (
                  <Box
                    key={`addoneValue${index2}`}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      label="Value"
                      variant="outlined"
                      size="small"
                      sx={{
                        background: "white",
                        height: "40px",
                        my: "10px",
                        width: "65%",
                      }}
                      value={item2.name}
                      onChange={(e) =>
                        changeSigleAddOnValue(
                          e.target.value,
                          "name",
                          index,
                          index2
                        )
                      }
                      required
                    />
                    <TextField
                      type={"number"}
                      label="Price"
                      variant="outlined"
                      size="small"
                      sx={{
                        background: "white",
                        height: "40px",
                        my: "10px",
                        width: "30%",
                      }}
                      value={item2.price}
                      onChange={(e) =>
                        changeSigleAddOnValue(
                          e.target.value,
                          "price",
                          index,
                          index2
                        )
                      }
                      required
                    />
                  </Box>
                ))}
                <Button
                  sx={{
                    fontSize: "16px",
                    textTransform: "none",
                    width: "100%",
                  }}
                  onClick={() => addNewOption(index)}
                  variant="outlined"
                >
                  Add new Option
                </Button>
              </form>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", my: "25px" }}>
        <CommonButton
          sx={{
            height: "40px",
            width: "90px",
            mr: "10px",
            textTransform: "none",
          }}
          variant="outlined"
          action={setBacktoinfo}
        >
          Back
        </CommonButton>

        <CommonButton
          type="submit"
          sx={{
            height: "40px",
            width: "90px",
            background: "var(--red)",
            textTransform: "none",
          }}
          variant="contained"
          action={action}
        >
          Next
        </CommonButton>
      </Box>
    </div>
  );
}
