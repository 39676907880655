import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllVendors } from "../../services/master/master";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
const drawerWidth = 270;

export default function AllVendors() {
  const { master } = useSelector((state) => state);
  const [vendors, setAllVendors] = useState([]);
  const fetchData = async () => {
    const data = await getAllVendors(master.token);
    setAllVendors(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Typography
          sx={{
            px: 3,
            py: 2,
            "@media (max-width: 600px)": {
              pl: 8, // margin value for small screens only
            },
            color: "#737173",
            fontSize: "25px",
          }}
        >
          Vendor
        </Typography>
        <Divider />
        <Box
          sx={{
            py: 3,
            px: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: "#fff" }}
          />
          <Button variant="contained" href="/addvendor">
            Add Vendor
          </Button>
        </Box>
        <Box sx={{ mx: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Sl No</StyledTableCell>
                  <StyledTableCell align="center">
                    Business Name
                  </StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">Location</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendors.length > 0 &&
                  vendors.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.businessName}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">
                        {row.businessAddress}
                      </TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        <VisibilityIcon />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
