import { Box } from "@mui/material";

function Report() {
  return (
    <Box
      sx={{
        padding: "40px",
        paddingTop: "140px",
      }}
    >
      <div>Report</div>
    </Box>
  );
}

export default Report;
