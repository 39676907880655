import Cookies from "js-cookie";

export function vendorReducer(
  state = Cookies.get("vendor") ? JSON.parse(Cookies.get("vendor")) : null,
  action
) {
  switch (action.type) {
    case "VENDOR_LOGIN":
      return action.payload;

    case "VENDOR_LOGOUT":
      return null;

    default:
      return state;
  }
}
