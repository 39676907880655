import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SearchBar from "../commenComponents/SearchBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import CommonButton from "../commenComponents/CommonButton";
import {
  findAllCategory,
  findAllSubCategory,
  getAllDepartment,
} from "../../../services/vendor/vendor";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ProductCategory({
  setShowInfoPage,
  product,
  setproduct,
}) {
  const vendor = useSelector((state) => state.vendor);
  const [expanded, setExpanded] = useState("");
  const [allDepartment, setAllDepartment] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [expandedSub, setExpandedSub] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setExpandedSub("");
  };

  const handleChangeSub = (panel) => (event, newExpanded) => {
    setExpandedSub(newExpanded ? panel : false);
  };

  const setShowInfoPageAct = () => {
    setShowInfoPage(true);
  };

  useEffect(() => {
    fetchAllDepartment();
  }, []);

  const fetchAllDepartment = async () => {
    const data = await getAllDepartment(vendor.token);
    setAllDepartment(data);
  };

  const fetchCategory = async (id) => {
    setproduct({ ...product, department: id });
    const data = await findAllCategory(id, vendor.token);
    setAllCategory(data);
  };

  const fetchSubCategory = async (id) => {
    setproduct({ ...product, category: id });
    const data = await findAllSubCategory(id, vendor.token);
    setAllSubCategory(data);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <div>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "15px" }}>
          Search for category
        </Typography>
        <SearchBar />
      </div>
      <div>
        <Typography sx={{ my: "10px" }} style={{ fontSize: "15px" }}>
          Select a category
        </Typography>

        {allDepartment?.map((row, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              onClick={() => fetchCategory(row._id)}
            >
              <Typography>{row.department}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "0px" }}>
              <div>
                {allCategory.length > 0 ? (
                  allCategory?.map((rowcat, index) => (
                    <Accordion
                      expanded={expandedSub === `panelSub1${index}`}
                      onChange={handleChangeSub(`panelSub1${index}`)}
                    >
                      <AccordionSummary
                        sx={{ background: "#F2F3F8" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        onClick={() => fetchSubCategory(rowcat._id)}
                      >
                        <Typography>{rowcat.category}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {allSubCategory?.map((rowSub) => (
                          <Typography>
                            <Button
                              sx={{
                                width: "100%",
                                justifyContent: "flex-start",
                                background:
                                  product.subCategory === rowSub._id
                                    ? "var(--border-gray)"
                                    : "",
                                ":hover": {
                                  bgcolor:
                                    product.subCategory === rowSub._id
                                      ? "var(--border-gray)"
                                      : "",
                                },
                              }}
                              onClick={() => {
                                setproduct({
                                  ...product,
                                  subCategory: rowSub._id,
                                });
                              }}
                            >
                              {rowSub.subCategory}
                            </Button>
                          </Typography>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Accordion
                    expanded={expandedSub === `panelSub1${index}`}
                    onChange={handleChangeSub(`panelSub1${index}`)}
                  >
                    <AccordionSummary
                      sx={{ background: "#F2F3F8" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography>
                        Sorry, this category is currently empty. Check back soon
                        for new products!
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div>
        <Typography
          sx={{ my: "10px", display: "flex", justifyContent: "end" }}
          style={{ fontSize: "13px" }}
        >
          Can’t find what you are looking for ? Request Category
        </Typography>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Link to={"/catalogue"}>
            <CommonButton
              sx={{
                height: "40px",
                width: "90px",
                mr: "10px",
                textTransform: "none",
              }}
              variant="outlined"
            >
              Back
            </CommonButton>
          </Link>

          {product.category !== "" && (
            <CommonButton
              sx={{
                height: "40px",
                width: "90px",
                background: "var(--red)",
                textTransform: "none",
              }}
              variant="contained"
              action={setShowInfoPageAct}
            >
              Next
            </CommonButton>
          )}
        </div>
      </div>
    </div>
  );
}
