import React from "react";
import { useLocation } from "react-router-dom";
import VendorNavbar from "../vendor/vendorNavbar/VendorNavbar";

export default function NavBar() {
  const location = useLocation();
  console.log(location);
  return (
    <div>
      {location.pathname === "/login" ? (
        <></>
      ) : location.pathname === "/" ||
        location.pathname === "/catalogue" ||
        location.pathname === "/addproduct" ||
        location.pathname === "/inventory" ||
        location.pathname === "/orders" ||
        location.pathname === "/promotion" ||
        location.pathname === "/report" ||
        location.pathname === "/delivery" ||
        location.pathname === "/users" ||
        location.pathname === "/customization" ? (
        <VendorNavbar />
      ) : (
        ""
      )}
    </div>
  );
}
