import { Box } from "@mui/material";
import { useState } from "react";
import ActiveOrder from "../../components/vendor/orders/ActiveOrder";
import OrederHistory from "../../components/vendor/orders/OrederHistory";
import PendingOrder from "../../components/vendor/orders/PendingOrder";
import ThirdNavbar from "../../components/vendor/vendorNavbar/ThirdNavbar";

function Orders() {
  const [page, setPage] = useState("pending");

  const [navBarItems, setNavBarItems] = useState([
    {
      name: "Pending Order",
      page: "pending",
      status: true,
      show: true,
      action: navBarAction,
    },
    {
      name: "Active Order",
      page: "active",
      status: false,
      show: true,
      action: navBarAction,
    },
    {
      name: "Order History",
      page: "history",
      status: false,
      show: true,
      action: navBarAction,
    },
  ]);

  function navBarAction(itemIndex) {
    const items = navBarItems.map((item, index) => {
      if (itemIndex === index) {
        setPage(item.page);
        return { ...item, status: true };
      } else {
        return { ...item, status: false };
      }
    });
    setNavBarItems(items);
  }

  return (
    <Box
      sx={{
        paddingTop: "173px",
      }}
    >
      <ThirdNavbar items={navBarItems} />
      <Box sx={{ mx: "80px" }}>
        {page == "pending" ? (
          <PendingOrder />
        ) : page === "active" ? (
          <ActiveOrder />
        ) : (
          <OrederHistory />
        )}
      </Box>
    </Box>
  );
}

export default Orders;
