import axios from "axios";
export const host = process.env.REACT_APP_BACKEND_URL;

export const vendorLogin = async (datas) => {
  try {
    const { data } = await axios.post(`${host}/vendorLogin`, datas);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addProductImages = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/addProductImages`, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteProductImages = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${host}/deleteProductImages`,
      { images: datas },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addProduct = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/addProduct`, datas, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(data);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateProduct = async (datas, variationData, token) => {
  try {
    const { data } = await axios.post(
      `${host}/updateProduct`,
      { datas: datas, variationData: variationData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllDepartment = async (token) => {
  try {
    const { data } = await axios.get(
      `${host}/findAllDepartment`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findAllCategory = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${host}/findAllCategory/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findAllSubCategory = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${host}/findAllSubCategory/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchAllVendorProduct = async (token) => {
  try {
    const { data } = await axios.get(`${host}/fetchAllVendorProduct`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const deleteVariation = async (proId, qty, varId, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/deleteVariation/${proId}/${qty}/${varId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
