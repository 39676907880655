import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/vendor/Dashboard";
import Catalogue from "./pages/vendor/Catalogue";
import Inventory from "./pages/vendor/Inventory";
import Orders from "./pages/vendor/Orders";
import Promotion from "./pages/vendor/Promotion";
import Report from "./pages/vendor/Report";
import Delivery from "./pages/vendor/Delivery";
import Users from "./pages/vendor/Users";
import Customization from "./pages/vendor/Customization";
import LoginVendor from "./pages/vendor/LoginVendor";
import LoggedInVendor from "./routes/LoggedInVendor";
import NotLoggedInVendor from "./routes/NotLoggedInVendor";
import MasterLogin from "./pages/master/MasterLogin";
import LoggedInMaster from "./routes/LoggedInMaster";
import NotLoggedInMaster from "./routes/NotLoggedInMaster";
import AllVendors from "./pages/master/AllVendors";
import MasterDashboard from "./pages/master/Dashboard";
import NavBar from "./components/navBar/NavBar";
import Addproduct from "./pages/vendor/Addproduct";
import AddVendor from "./pages/master/AddVendor";
import Subscription from "./pages/master/Subscription";
import UpgradeSubscription from "./pages/master/UpgradeSubscription";
import Payment from "./pages/master/Payment";
import ErrorPage from "./pages/vendor/ErrorPage";

function App() {
  return (
    <div className="app">
      <NavBar />
      <ToastContainer />
      <Routes>
        <Route element={<LoggedInVendor />}>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/catalogue" exact element={<Catalogue />} />
          <Route path="/addproduct" exact element={<Addproduct />} />
          <Route path="/inventory" exact element={<Inventory />} />
          <Route path="/orders" exact element={<Orders />} />
          <Route path="/promotion" exact element={<Promotion />} />
          <Route path="/report" exact element={<Report />} />
          <Route path="/delivery" exact element={<Delivery />} />
          <Route path="/users" exact element={<Users />} />
          <Route path="/customization" exact element={<Customization />} />
        </Route>
        <Route element={<NotLoggedInVendor />}>
          <Route path="/login" element={<LoginVendor />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
        {/* <Route element={<LoggedInMaster />}>
          <Route path="/dashboard" exact element={<MasterDashboard />} />
          <Route path="/allvendors" exact element={<AllVendors />} />
          <Route path="/addvendor" exact element={<AddVendor />} />
          <Route path="/subscription" exact element={<Subscription />} />
          <Route path="/upgrade-subscription" exact element={<UpgradeSubscription />} />
          <Route path="/payment" exact element={<Payment />} />
        </Route> */}
        {/* <Route element={<NotLoggedInMaster />}>
          <Route path="/masterlogin" element={<MasterLogin />} />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
