import { Box } from "@mui/material";
import React from "react";
import AddProduct from "../../components/vendor/addProducr/AddProduct";
import ThirdNavbar from "../../components/vendor/vendorNavbar/ThirdNavbar";

export default function Addproduct() {
  const navBarItems = [
    {
      name: "Create Item",
      status: true,
      show: true,
    },
  ];

  return (
    <Box
      sx={{
        paddingTop: "173px",
      }}
    >
      <ThirdNavbar items={navBarItems} />
      <AddProduct />
    </Box>
  );
}
