import { Box } from "@mui/material";
import TopCard from "../../components/vendor/dashboard/TopCard";
import Chart from "../../components/vendor/dashboard/Chart";
import OrderHistory from "../../components/vendor/dashboard/OrderHistory";

export default function Dashboard() {
  return (
    <Box
      sx={{
        padding: "40px",
        paddingTop: "140px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TopCard content={"Orders"} value={459} />
        <TopCard content={"Today’s Sales"} value={"₹" + "40,000.24"} />
        <TopCard content={"Total Balance"} value={"₹" + "14,400,0.24"} />
        <TopCard content={"Customer Feedback"} value={`⭐` + 4.4} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Chart />
        <OrderHistory />
      </Box>
    </Box>
  );
}
