import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  { name: "Dashboard", href: "/" },
  { name: "Catalogue", href: "/catalogue" },
  { name: "Inventory", href: "/inventory" },
  { name: "Orders", href: "/orders" },
  { name: "Promotion", href: "/promotion" },
  { name: "Report", href: "/report" },
  { name: "Delivery", href: "/delivery" },
  { name: "Users", href: "/users" },
  { name: "Customization", href: "/customization" },
];

function VendorSubNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
            style={{
              textDecoration: "none",
              color: "var(--font-black)",
            }}
            to={item.href}
          >
            <ListItem key={item.name} disablePadding>
              <ListItemButton sx={{ textAlign: "start" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          marginTop: "65px",
          background: "var(--bg-white)",
          borderBottom: "solid var(--border-gray) 1px",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ color: "var(--font-black)", display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: "100%",
              marginRight: { lg: "250px" },
              marginLeft: { lg: "50px" },
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",

                justifyContent: "space-between",
              }}
            >
              {navItems.map((item) => (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "var(--font-black)",
                  }}
                  to={item.href}
                >
                  <Button key={item.name} sx={{ color: "var(--font-black)" }}>
                    {item.name}
                  </Button>
                </Link>
              ))}
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default VendorSubNavbar;
